<template>
  <div class="container-box">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      type="border-card"
      class="demo-tabs"
    >
      <el-tab-pane label="省赛" name="1"></el-tab-pane>
      <el-tab-pane label="全国总决赛" name="2"></el-tab-pane>
      <el-tab-pane label="教师赛" name="3"></el-tab-pane>
    </el-tabs>
    <el-table
      :data="payinvoice_data"
      stripe
      border
      style="width: 100%"
    >
      <el-table-column label="收款单位" prop="company_info" align="center"></el-table-column>
      <el-table-column label="总缴费金额" prop="invoice_amount" align="center"></el-table-column>
      <el-table-column label="可开发票金额" prop="available_amount" align="center"></el-table-column>
      <el-table-column label="操作" prop="ocr_company" align="center">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="invoicing_btn(scope.row)">
            开票
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%; margin-top: 20px;"
    >
      <el-table-column label="收款单位" prop="company_info" align="center" width="250px"></el-table-column>
      <el-table-column label="开票金额" prop="invoice_value" align="center"></el-table-column>
      <el-table-column label="开票时间" prop="update_time" align="center"></el-table-column>
      <el-table-column label="税号" prop="duty_paragraph" align="center"></el-table-column>
      <el-table-column label="发票抬头" prop="invoice_title" align="center"></el-table-column>
      <el-table-column label="状态" prop="state" align="center"></el-table-column>
      <el-table-column label="操作" prop="ocr_company" align="center">
        <template #default="scope">
          <el-button v-if="scope.row.state == '开票成功'" link type="primary" size="small" @click="downloadInvoiceBtn(scope.row.id)">
            下载发票
          </el-button>
          <el-button v-else-if="scope.row.state == '提交失败' || scope.row.state == '开票失败'" link type="primary" size="small" @click="reInvoiceBtn(scope.row.id)">
            重开发票
          </el-button>
          <el-button v-else-if="scope.row.state == '提交成功'" link type="primary" size="small">
            请等待，刷新后查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-table
      :data="tableData"
      stripe
      border
      :default-expand-all="true"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div class="inputContent">
            <div
              v-for="(item, index) in props.row.ocr_number_select"
              :key="index"
            >
              <div class="outDiv" v-if="index < props.row.num">
                <div class="itemLabel">开票金额:&nbsp;</div>
                <div class="itemContent">
                  <el-input
                    :disabled="disable"
                    @change="changeMoney(props.$index, props.row)"
                    clearable
                    v-model="props.row.ocr_number_select[index]"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入金额"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收款单位" prop="ocr_company" align="center">
      </el-table-column>
      <el-table-column label="人数" prop="ocr_number" width="60" align="center">
      </el-table-column>
      <el-table-column label="金额" prop="ocr_money" width="90" align="center">
      </el-table-column>
      <el-table-column
        label="发票内容"
        prop="invoice_content"
        width="170"
        align="center"
      >
        <template #default="scope">
          <el-select
            v-model="scope.row.invoice_con"
            :disabled="disable"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in scope.row.invoice_content"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="开票数量" prop="num" width="90" align="center">
        <template #default="scope">
          <el-select
            v-model="scope.row.num"
            :disabled="scope.row.ocr_number_status || disable"
            placeholder="请选择"
            @change="change(scope.$index, scope.row)"
          >
            <el-option
              v-for="item in optionsNum"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="发票备注（此项为发票的备注栏内容）"
        align="center"
      >
        <template #default="scope">
          <div>
            <el-input
              :disabled="disable"
              v-model="scope.row.ocr_memo"
              @change="handleInputReset(scope.row, scope.$index)"
              size="small"
            />
          </div>
        </template>
      </el-table-column>
    </el-table> -->
    <!-- 开票弹框 -->
    <el-dialog
      v-model="isdialogshow"
      width="70%"
      top="10%"
      center
      destroy-on-close
      :before-close="handleClose_Invoicing"
    >
      <div class="top-wrap" v-if="payinvoice_data.length > 0">
        <div class="formItem">
          <div class="itemWrap">
            <div class="listItem">
              <div
                class="item iTitle icon-label"
                style="border-bottom: 1px solid #ecedf0"
              >
                发票抬头:
              </div>
              <div class="item content" style="border-bottom: 1px solid #ecedf0">
                <el-input
                  v-model="invoInfo.invoice_title"
                  placeholder="请输入发票抬头"
                  :disabled="disable"
                ></el-input>
              </div>
              <div class="item iTitle icon-label">税&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:</div>
              <div class="item content">
                <el-input
                  v-model="invoInfo.duty_paragraph"
                  oninput="value=value.replace(/[^A-Za-z0-9]/g,'')"
                  placeholder="请输入税号"
                  :disabled="disable"
                ></el-input>
              </div>
            </div>
            
            <div class="listItem">
              <div
                class="item iTitle icon-label"
                style="border-bottom: 1px solid #ecedf0"
              >
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:
              </div>
              <div class="itemEamil" style="border-bottom: 1px solid #ecedf0">
                <el-input
                  @blur.native.capture="isEmail()"
                  v-model.trim="invoInfo.email"
                  placeholder="请输入收票邮箱"
                  :disabled="disable"
                ></el-input>
              </div>

              <div
                class="item iTitle icon-label"
                style="border-bottom: 1px solid #ecedf0"
              >
                手机号:
              </div>
              <div class="itemEamil" style="border-bottom: 1px solid #ecedf0">
                <el-input
                  @blur.native.capture="isPhone()"
                  v-model.trim="invoInfo.phone"
                  :disabled="disable"
                ></el-input>
              </div>

              <div
                class="item iTitle icon-label"
                style="border-bottom: 1px solid #ecedf0"
              >
                发票内容:
              </div>
              <div class="itemEamil" style="border-bottom: 1px solid #ecedf0">
                <el-select v-model="invoInfo.invoice_content" placeholder="请选择" clearable>
                  <el-option
                      v-for="item in invoiceList"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                </el-select>
              </div>
            </div>
            <div class="listItem" style="border-bottom: 1px solid #ecedf0;">
              <div class="item iTitle">发票栏备注:</div>
              <div class="notes">
                <el-input
                  v-model="invoInfo.memo"
                  placeholder="请输入发票栏备注"
                  :disabled="disable"
                ></el-input>
              </div>
            </div>
            <div class="listItem" style="border: none;">
              <div class="addInvoice">
                <el-button class="customButton" type="primary" :icon="Plus"  @click="addInput">
                  <div class="iconBox_add">
                    <el-icon :size="20" class="icon">
                      <Plus />
                    </el-icon>
                  </div>
                  添加发票
                </el-button>
              </div>
            </div>
            <div class="listItem" style="margin-top: 10px;">
              <div class="inputContainer">
                <div v-for="(item, index) in inputList" :key="index" class="inputRow">
                  <div
                    class="item iTitle icon-label"
                  >
                    发票{{ index + 1 }}
                  </div>
                  <div class="notes">
                     <div class="inputTitle">金额</div><el-input v-model.number="inputList[index]" placeholder="请输入内容" class="input" @blur.native.capture="validateInput(index)" />
                      <div class="addicon" @click="delInput(index)" style="color: #409eff;">
                        <el-button class="customButton" type="primary" :icon="Plus">
                          <div class="iconBox_del">
                            <el-icon :size="20" class="icon">
                              <Minus />
                            </el-icon>
                          </div>
                          删&nbsp;&nbsp;&nbsp;&nbsp;除
                        </el-button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="promptText">*注:1、请仔细核对开票信息，一旦开具后不退不换:2、每张开票金额为120元的倍数。</div>
            <div class="btn">
              <el-button type="primary" @click="ConfirmInvoicing">确认开票</el-button>
              <!-- <div class="promptText"></div>
              <span
                class="step_s"
                v-if="state == '未保存' || state == '已保存'"
                @click="nextStep()"
                >提交</span
              >
              <span class="step_e" v-else>提交</span> -->
              <!-- <div class="promptText">
                <p>注：</p>
                <p>1、因开票数量较多，报名总人数小于30人的只能开具一张发票。</p>
                <p>2、发票一旦提交开具后不退不换，请仔细确认填写的发票信息。</p>
                <p class="hh">
                  3、发票按照登记的先后顺序进行开具，预计周期为10-20个工作日，开具完成后会发送至登记的邮箱，请注意查收。
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    
    <!-- 确认开票弹框 -->
    <el-dialog
      v-model="dialogVisible"
      width="70%"
      top="20px"
      center
      destroy-on-close
      :before-close="handleClose"
    >
      <div class="dialogContent">
        <div class="title">开票结果</div>
        <div class="careful">*请仔细核对发票信息。</div>
        <div
          class="invoiceInform"
          
        >
          <div class="tableContent">
            <div class="company">
              <span>开票公司：</span>
              <span>{{ selectRow.company_info }}</span>
            </div>

            <div class="invoice_content">
              <span>发票内容：</span>
              <span>{{ invoInfo.invoice_content }}</span>
            </div>

            <div class="invoice_memo_num">
              <div class="invo_memo">
                <span>发票备注：</span>
                <span>{{ invoInfo.memo }}</span>
              </div>
              <div class="invo_num">
                <span>发票张数：</span>
                <span>{{ inputList.length }}</span>
              </div>
            </div>
            <div class="invoice_money">
              <div>
                <div class="money">
                  <span>发票金额：</span>
                  <span>{{ inputList.join("元, ") }}元</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="invoFootInfo">
          <div class="info">
            <span>发票抬头：</span>
            <span>{{ invoInfo.invoice_title }}</span>
          </div>
          <div class="info">
            <span>税号：</span>
            <span>{{ invoInfo.duty_paragraph }}</span>
          </div>
          <div class="info">
            <span>收票邮箱：</span>
            <span>{{ invoInfo.email }}</span>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div @click="commit" class="confirm">确认提交</div>
          <div @click="dialogVisible = false" class="backBtn">返回修改</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios"; // 引入axios
import { invoiceInfo } from "api/apis.js";
import { ElMessage } from "element-plus";
import { Plus, Minus } from '@element-plus/icons-vue';
export default {
  name: "invoiceInformation",
  components: { Plus, Minus },
  data() {
    return {
      payinvoice_data: [],
      optionsNum: [
        {
          value: 1,
          label: 1,
        },
        {
          value: 2,
          label: 2,
        },
        {
          value: 3,
          label: 3,
        },
        {
          value: 4,
          label: 4,
        },
        {
          value: 5,
          label: 5,
        },
      ],
      dialogVisible: false,
      isdialogshow: false,
      invoice_data: [],
      tableData:[],
      invoInfo: {
        invoice_title: "", //发票抬头
        memo: "", //发票备注
        email: "", //发票邮箱
        duty_paragraph: "", //税号
        phone: "", //手机号
        invoice_content: "" , //发票类型
      },
      state: "未保存",
      moneyFlag: true,
      inputMoneyFlag: false,
      iFshowEmail: false,
      contentFlag: true,
      disable: null,
      activeName: "1",
      competition_type: "省赛",
      invoiceList: [
        // '现代服务*报名费',
        // '现代服务*技术服务费',
        // '现代服务*会务费',
        // '现代服务*会议费'
      ],
      inputList: [], // 保存动态输入框数据的数组
      available_amount: 0, // 可开发票金额
      selectRow: {},  // 选中行数据
      dialogParams: {},  // 开票参数
      total: '', // 发票总金额
    };
  },
  created() {},
  mounted() {
    let phoneNum = JSON.parse(localStorage.getItem('UPuser')).telephone || '';
    this.invoInfo.phone = phoneNum;
    this.invoiceData();
    this.getInvoiceInfo();
    this.isLastTwoDaysOfMonthFromServer().then(result => {
      if (result === null) {
        console.log("无法确定时间，请检查网络连接");
      } else if (result) {
        console.log("✅ 今天是本月的最后两天！");
        this.$alert('尊敬的客户：<br><br>&nbsp&nbsp&nbsp&nbsp您好！因税务局系统每月月底最后两天例行维护，我司电子发票服务平台在此期间可能出现网络延迟、开票功能暂停等情况。为确保您顺利开具发票，请尽量提前完成当月开票需求，避开月底维护时段。维护结束后，系统将自动恢复，感谢您的理解与配合！<br><br>因系统维护给您带来的不便，我们深表歉意。', 
        '关于避免月底系统维护期间开票的温馨提示', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true, // 允许 HTML
          callback: action => {}
        });
      } else {
        console.log("❌ 今天不是本月的最后两天！");
      }
    });
  },
  methods: {
    async isLastTwoDaysOfMonthFromServer() {
      try {
        // 获取网络时间
        const response = await fetch("https://www.timeapi.io/api/Time/current/zone?timeZone=UTC");
        const data = await response.json();
        const serverDate = new Date(data.dateTime); // 解析网络时间

        // 计算当前月的最后一天
        const lastDay = new Date(serverDate.getFullYear(), serverDate.getMonth() + 1, 0).getDate();
        
        // 判断今天是否是最后两天
        return serverDate.getDate() >= lastDay - 1;
      } catch (error) {
        console.error("获取服务器时间失败:", error);
        return null; // 遇到错误时返回 null，避免程序崩溃
      }
    },
    addInput() {
      this.inputList.push(""); // 添加一个空字符串作为新行
    },
    delInput(index) {
      this.inputList.splice(index, 1); 
    },
    handleClick(name) {
      this.payinvoice_data = [];
      if (name.props.name == "1") {
        this.competition_type = "省赛";
      } else if (name.props.name == "2") {
        this.competition_type = "国赛";
      } else if (name.props.name == "3") {
        this.competition_type = "教师赛";
      }
      this.invoiceData();
      this.getInvoiceInfo();
    },
    // 点击下载发票
    downloadInvoiceBtn( id ){
      let dataobj = new FormData();
      dataobj.append( "id",id );
      invoiceInfo.downloadInvoice(dataobj).then((res) => {
        if(res.code == '200'){
          window.open(JSON.parse(res.url));
        }
      })
    },
    // 重开发票
    reInvoiceBtn( id ){
      let dataobj = new FormData();
      dataobj.append( "id",id );
      invoiceInfo.reInvoice(dataobj).then((res) => {
        if(res.code == '200'){
          this.invoiceData();
          this.getInvoiceInfo();
          this.$message.success("重开发票成功");
          return 
        }else {
          this.$message.error(res.msg);
          return false;
        }
      })
    },
    // 点击开票按钮
    invoicing_btn(row) {
      this.isdialogshow = true;
      this.selectRow = row;
      this.available_amount = this.selectRow.available_amount
      this.invoiceList  = this.selectRow.invoice_content
      this.inputList = []
      //获取开票弹框信息
      let dataobj = new FormData();
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      invoiceInfo.getInvoiceInfo(dataobj).then((res) => {
        if (res.code == "200") {
          let dataobj = JSON.parse(res.data);
          if(dataobj){
            const { invoice_title, memo, email, duty_paragraph, phone, invoice_content} = dataobj;
            this.invoInfo.invoice_title = invoice_title;
            this.invoInfo.memo = memo;
            this.invoInfo.email = email;
            this.invoInfo.duty_paragraph = duty_paragraph;
            this.invoInfo.phone = phone;
            this.invoInfo.invoice_content = invoice_content;
            console.log(dataobj,'开票信息弹框');
          }
        }
      })

    },
    // 确认开票按钮
    ConfirmInvoicing() {
      console.log(this.selectRow.available_amount);
      // 判断开发票金额是否超过可开发票金额
      this.total = this.inputList.reduce((a, b) => Number(a) + Number(b), 0)
      if (this.total > this.selectRow.available_amount) {
        this.$message.error("开发票金额不能超过可开发票金额");
        return false;
      }
      // 判断必填项是否为空
      if( !this.selectRow.company_info || !this.invoInfo.duty_paragraph || !this.invoInfo.invoice_title || !this.invoInfo.email
        || !this.invoInfo.phone || this.total==0 ){
          ElMessage({
            message: "有必填项未填写!",
            type: "error",
          });
          return false;
      }
      let flag = true
      this.inputList.forEach((item) => {
        if(!item){
          ElMessage({
            message: "有必填项未填写!",
            type: "error",
          });
          flag = false
          return false;
        }
      })
      if(!flag) return false;
      this.dialogVisible = true;
      let dataobj = new FormData();
      dataobj.append("user_id", JSON.parse(localStorage.getItem("UPuser")).user_id)
      dataobj.append("company_info", this.selectRow.company_info) // 收款单位
      dataobj.append("invoice_type", '普票') 
      dataobj.append("duty_paragraph", this.invoInfo.duty_paragraph)  // 税号
      dataobj.append("invoice_title", this.invoInfo.invoice_title)  // 发票抬头
      dataobj.append("memo", this.invoInfo.memo)  // 备注
      dataobj.append("email", this.invoInfo.email)  // email
      dataobj.append("phone", this.invoInfo.phone)  // 手机号
      dataobj.append("competition_type", this.competition_type)  // 省赛/国赛/教师赛
      dataobj.append("invoice_content", this.invoInfo.invoice_content)  // 发票内容
      dataobj.append("invoice_values", JSON.stringify(this.inputList))  // 发票金额
      console.log(this.inputList,'弹框参数---');
      console.log(this.selectRow,'选中行');
      this.dialogParams = dataobj;
    },
    //获取开票数据
    invoiceData() {
      let dataobj = new FormData();
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      dataobj.append("competition_type", this.competition_type);
      invoiceInfo.payInvoice(dataobj).then((res) => {
        if (res.code == "200") {
          let dataobj = JSON.parse(res.data);
          if (dataobj) {
            this.payinvoice_data = dataobj.payment_merge_data
          //   const { payment_merge_data, invoice_data } = dataobj;
          //   this.tableData = payment_merge_data || [];
          //   this.invoice_data = invoice_data || [];
          //   this.tableData.forEach((tableItem) => {
          //     if (!tableItem.ocr_number_select) {
          //       tableItem.ocr_number_select = ["", "", "", "", ""];
          //     }
          //     if (!tableItem.num) {
          //       tableItem.num = 1;
          //       tableItem.ocr_number_select = [""];
          //     }
          //     if (
          //       !tableItem.invoice_con &&
          //       tableItem.invoice_content.length > 0
          //     ) {
          //       tableItem.invoice_con = 0;
          //     }
          //     tableItem.ocr_number_status =
          //       tableItem.ocr_number >= 30 ? false : true;
          //     //发票内容、开票数量、下拉输入框从company_info中获取，其他的取payment_merge_data
          //     if (this.invoice_data.length > 0) {
          //       this.invoInfo.invoice_title =
          //         this.invoice_data[0].invoice_title;
          //       this.invoInfo.memo = this.invoice_data[0].memo;
          //       this.invoInfo.email = this.invoice_data[0].email;
          //       this.invoInfo.duty_paragraph =
          //         this.invoice_data[0].duty_paragraph;
          //       this.state = this.invoice_data[0].state;
          //       this.disable =
          //         this.state != "未保存" && this.state != "已保存"
          //           ? true
          //           : false;
          //       if (
          //         this.invoice_data[0].company_info &&
          //         this.invoice_data[0].company_info.length > 0
          //       ) {
          //         this.invoice_data[0].company_info.forEach((item) => {
          //           if (item.ocr_company == tableItem.ocr_company) {
          //             tableItem.invoice_con = item.invoice_con;
          //             if (item.ocr_number_select.length > 0) {
          //               tableItem.ocr_number_select = item.ocr_number_select;
          //             } else {
          //               tableItem.ocr_number_select = [""];
          //             }
          //             if (item.num) {
          //               tableItem.num = item.num;
          //             } else {
          //               if (tableItem.ocr_number_status) {
          //                 tableItem.num = 1;
          //               }
          //             }
          //             tableItem.ocr_memo = item.ocr_memo;
          //           }
          //         });
          //       }
          //     }
          //   });
          }
        } else {
          ElMessage({
            message: "获取数据失败！",
            type: "error",
          });
        }
      });
      this.$forceUpdate();
    },
    // 开票信息查询
    getInvoiceInfo(){
      let dataobj = new FormData();
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      dataobj.append("competition_type", this.competition_type);
      invoiceInfo.invoiceData(dataobj).then((res) => {
        if (res.code == "200") {
          let dataobj = JSON.parse(res.data);
          if(dataobj){
            console.log(dataobj,'开票信息查询');
            this.tableData = dataobj;
          }
        }
      })
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    handleClose_Invoicing(){
      this.isdialogshow = false
    },
    // 确认提交
    commit() {
      invoiceInfo.ConfirmInvoice(this.dialogParams).then((res) => {
        console.log(res,'开票');
        if (res.code == "200") {
          this.$message.success("开票成功");
          this.dialogVisible = false;
          this.isdialogshow = false;
          setTimeout(() => {
            this.invoiceData();
            this.getInvoiceInfo(); 
          },5000)
        }else {
          this.$message.error(res.msg);
          return false;
        }
      })
    },
    changeMoney(index, row) {},
    change(index, row) {
      this.payinvoice_data[index].ocr_number_select = ["", "", "", "", ""];
    },
    handleInputReset(row, index) {
      if (row) {
        this.payinvoice_data[index].ocr_memo = row.ocr_memo;
      }
    },
    // 邮箱验证
    isEmail() {
      let myreg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (this.invoInfo.email == "") {
        this.$message.error("请输入邮箱");
      } else if (!myreg.test(this.invoInfo.email)) {
        this.$message.error("邮箱格式不正确");
        this.invoInfo.email = '';
        this.iFshowEmail = false;
        return;
      } else {
        this.iFshowEmail = true;
      }
    },
    // 手机号验证
    isPhone() {
      let myreg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
      if (this.invoInfo.phone == "") {
        this.$message.error("请输入手机号");
      } else if (!myreg.test(this.invoInfo.phone)) {
        this.$message.error("手机号格式不正确");
        this.invoInfo.phone = '';
        this.iFshowEmail = false;
        return;
      } else {
        this.iFshowEmail = true;
      }
    },
    // 是否为数字
    validateInput(index) {
      const regex = /^\d+$/;
      let isValid = regex.test(this.inputList[index]);
      if( isValid && this.inputList[index] % 120 === 0 ) {
        this.iFshowEmail = true;
      } else {
        this.$message.error("金额格式不正确/金额必须是120的倍数");
        this.inputList[index] = "";
        this.iFshowEmail = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
